<template>
  <van-form>
    <van-field
      :readonly="codeSended"
      label="邮箱"
      placeholder="已绑定的邮箱"
      ref="email"
      name="email"
      v-model="email"
      autocomplete="off"
    ></van-field>
    <van-field
      label="验证码"
      placeholder="请输入邮箱验证码"
      ref="code"
      name="code"
      type="number"
      autocomplete="off"
      v-model="code"
    >
      <template #button>
        <van-button
          :disabled="hideCodeBtn"
          @click="sendCode"
          size="small"
          type="primary"
          >{{ sendCodeBtnText }}</van-button
        >
      </template>
    </van-field>
    <van-field
      label="输入新密码"
      placeholder="6-20字英文数字"
      type="password"
      v-model="password"
      autocomplete="off"
    ></van-field>
    <van-field
      label="重复新密码"
      type="password"
      autocomplete="off"
      placeholder="重复一遍密码"
      v-model="repassword"
    ></van-field>
    <van-cell>
      <van-button @click="setPassword" block icon="success" round type="primary"
        >重置密码</van-button
      >
    </van-cell>
  </van-form>
</template>

<script>
import Vue from "vue";
import { isEmailAvailable } from "@/libs/tools";
import { Form, Field } from "vant";

Vue.use(Form).use(Field);

export default {
  name: "GetPasswordBySms",
  data() {
    return {
      limit: 60,
      code: "", // 填写的邮箱验证码
      hideCodeBtn: false,
      codeSended: false, // 邮箱验证码已发送成功
      email: "", //邮箱号
      password: "",
      repassword: "",
    };
  },
  computed: {
    sendCodeBtnText() {
      return this.hideCodeBtn ? "发送验证码 " + this.limit : "发送验证码";
    },
  },
  methods: {
    setPassword() {
      if (!this.email || !isEmailAvailable(this.email)) {
        this.$dialog.alert({ message: "邮箱格式有误, 如使用QQ邮箱,请在QQ号后加 @qq.com" });
        return false;
      }
      const { email, code, password, repassword } = this;
      const param = { email, code, password, repassword };

      this.$http.post("set_password", param).then((res) => {
        this.$dialog.alert({ message: res.msg });
        if (res.code === 1) {
          this.$router.push({ name: "login" });
        }
      });
    },
    async sendCode() {
      if (!this.email || !isEmailAvailable(this.email)) {
        this.$dialog.alert({ message: "邮箱格式有误, 如使用QQ邮箱,请在QQ号后加 @qq.com" });
        return false;
      }

      const param = {
        email: this.email,
        action: 2,
      };
      await this.getToken().then((res) => {
        param.__token__ = res.token;
      });

      this.$http.post("send_email_code", param).then((res) => {
        if (res.code === 1) {
          this.codeSended = true;
          this.initTimer(60);
        }
        this.$dialog.alert({ message: res.msg }).then(() => {
          this.$refs.code.focus();
        });
      });
    },
    async getToken() {
      return await this.$http.get("token");
    },
    initTimer(limit) {
      this.limit = limit;
      this.hideCodeBtn = true;
      this.timer = setInterval(() => {
        this.limit--;
        sessionStorage.setItem("getPassTimer", this.limit); // 写入缓存,防止刷新页面后倒计时重置
        if (this.limit <= 0) {
          this.limit = 0;
          this.hideCodeBtn = false;
          clearInterval(this.timer);
        }
      }, 1000);
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
  created() {
    clearInterval(this.timer);
    const limit = sessionStorage.getItem("getPassTimer");
    if (limit > 0) {
      this.initTimer(+limit);
    }
    this.$store.commit("setPageTitle", "找回密码");
  },
};
</script>
