<template>
  <div class="page">
    <van-notice-bar wrapable :scrollable="false" left-icon="volume-o">
      <p>如果账号未绑定过手机号码 和 邮箱，请联系站长</p>
    </van-notice-bar>
    <van-tabs title-active-color="#ee0a24" v-model="tabActive">
      <van-tab title="手机短信找回">
        <by-sms />
      </van-tab>
      <van-tab title="邮箱找回">
        <by-email />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import { Tab, Tabs, NoticeBar } from "vant";
Vue.use(Tab).use(Tabs).use(NoticeBar);
import BySms from "./bySms.vue";
import ByEmail from "./byEmail.vue";

export default {
  name: "GetPassword",
  components: { BySms, ByEmail },
  data() {
    return {
      tabActive: 0,
    };
  },
  methods: {},
  created() {
    this.$store.commit("setPageTitle", "找回密码");
  },
};
</script>
